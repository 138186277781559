import React from 'react';
import { ShowLoader } from 'ui/component/show-loader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Page } from './page';
import { Dialog } from './dialog';

export const Body = ({ embroiderer }) => {
    const t = usePhraseTranslater();
    return (
        <Dialog loadState={embroiderer.loadState}>
            {embroiderer.loadState === 'loading' && (
                <ShowLoader message={t('Loading embroidery configurator...')} />
            )}
            {embroiderer.loadState === 'saving' && (
                <ShowLoader message={t('Submitting embroidered product...')} />
            )}
            {embroiderer.loadState === 'downloading' && (
                <ShowLoader message={t('Downloading embroidered product...')} />
            )}
            {embroiderer.loadState === 'myproduct' && (
                <ShowLoader message={t('Saving custom product...')} />
            )}
            {embroiderer.loadState === 'loaded' && (
                <Page embroiderer={embroiderer} />
            )}
        </Dialog>
    );
};
